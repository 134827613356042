import React from 'react'

function CompanyName() {
    const CompanyName = 'Ares Custom Yachts'


    return (
        <>
            <span aria-label="Ares Custom Yachts" title='Ares Custom Yachts, Yacht restorations, Yacht repairs, Yacht overhauls, Marine Paint, Florida USA'>{CompanyName}</span>


        </>
    )
}

export default CompanyName